import React from 'react';

// 404 page
export default function NoPage() {
   return (
      <div className='no-page'>
         <h1 className='m-auto'>404</h1>
      </div>
   );
}
