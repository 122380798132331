import React, {useState} from 'react';

import '../App.css';
import logoImg from '../assets/image/loandepot-logo-color.png';
import {formbackgrounds} from '../utils/constant';

export default function Main() {
   const [step, setStep] = useState(1);
   const [type, setType] = useState();
   const [home, setHome] = useState();
   const [credit, setCredit] = useState();
   const [propertyUse, setPropertyUse] = useState();

   const FormDiv = () => {
      if (step === 1) {
         return (
            <>
               <div id='type_input' className='form-div flex-column'>
                  <label>Type of Loan:</label>
                  <select
                     value={type}
                     onSelect={(evt) => {
                        setType(evt.target.value);
                     }}
                  >
                     <option value='Refinance'>Refinance</option>
                     <option value='Purchase'>Pruchase</option>
                  </select>
               </div>
               <button
                  className='continue-btn'
                  onClick={() => {
                     setStep(step + 1);
                  }}
               ></button>
            </>
         );
      } else if (step === 2) {
         return (
            <>
               <button
                  className='back-btn'
                  onClick={() => {
                     setStep(step - 1);
                  }}
               >
                  BACK
               </button>
               <div id='type_input' className='form-div flex-column'>
                  <label>Home Description:</label>
                  <select
                     value={home}
                     onSelect={(evt) => {
                        setHome(evt.target.value);
                     }}
                  >
                     <option value='Condominium'>Condominium</option>
                     <option value='Co-Op'>Co-Op</option>
                     <option value='Manufactured'>Manufactured</option>
                     <option value='Mobile'>Mobile</option>
                     <option value='Multiple Family'>Multiple Family</option>
                     <option value='Single Family'>Single Family</option>
                     <option value='TownHouse'>TownHouse</option>
                  </select>
               </div>
               <button
                  className='continue-btn'
                  onClick={() => {
                     setStep(step + 1);
                  }}
               ></button>
            </>
         );
      } else if (step === 3) {
         return (
            <>
               <button
                  className='back-btn'
                  onClick={() => {
                     setStep(step - 1);
                  }}
               >
                  BACK
               </button>
               <div id='type_input' className='form-div flex-column'>
                  <label>Your Credit Profile:</label>
                  <select
                     value={credit}
                     onSelect={(evt) => {
                        setCredit(evt.target.value);
                     }}
                  >
                     <option value='excellent'>Excellent: 720+</option>
                     <option value='good'>Good: 680-719</option>
                     <option value='fair'>Fair: 620-679</option>
                     <option value='poor'>{`Poor: <620`}</option>
                  </select>
               </div>
               <button
                  className='continue-btn'
                  onClick={() => {
                     setStep(step + 1);
                  }}
               ></button>
            </>
         );
      } else if (step === 4) {
         return (
            <>
               <button
                  className='back-btn'
                  onClick={() => {
                     setStep(step - 1);
                  }}
               >
                  BACK
               </button>
               <div id='type_input' className='form-div flex-column'>
                  <label>Property Use:</label>
                  <select
                     value={propertyUse}
                     onSelect={(evt) => {
                        setPropertyUse(evt.target.value);
                     }}
                  >
                     <option value='investment_property'>Investment Property</option>
                     <option value='owner_occupied'>Owner Occupied</option>
                     <option value='second_home'>Second Home</option>
                  </select>
               </div>
               <button
                  className='continue-btn'
                  onClick={() => {
                     setStep(step + 1);
                  }}
               ></button>
            </>
         );
      } else if (step === 5) {
         return (
            <>
               <button
                  className='back-btn'
                  onClick={() => {
                     setStep(step - 1);
                  }}
               >
                  BACK
               </button>
               <div id='type_input' className='form-div flex-column'>
                  <label>Your Zip Code:</label>
                  <input type='text' id='zip_code' maxLength={5} defaultValue={''} />
               </div>
               <button
                  className='continue-btn'
                  onClick={() => {
                     setStep(step + 1);
                  }}
               ></button>
            </>
         );
      } else if (step === 6) {
         return (
            <div className='d-flex'>
               <div className='six-div'>
                  <button
                     className='back-btn'
                     style={{top: '85%'}}
                     onClick={() => {
                        setStep(step - 1);
                     }}
                  >
                     BACK
                  </button>
                  <div className='d-flex my-3 six-input'>
                     <label className='my-auto'>First Name*</label>
                     <input className='my-auto' id='first_name' defaultValue={''} />
                  </div>
                  <div className='d-flex my-3 six-input'>
                     <label className='my-auto'>Last Name*</label>
                     <input className='my-auto' id='last_name' defaultValue={''} />
                  </div>
                  <div className='d-flex my-3 six-input'>
                     <label className='my-auto'>Primary Residence*</label>
                     <input className='my-auto' id='primary_residence' defaultValue={''} />
                  </div>
                  <div className='d-flex my-3 six-input'>
                     <label className='my-auto'>City*</label>
                     <input className='my-auto' id='city' defaultValue={''} />
                  </div>
                  <div className='d-flex my-3 six-input'>
                     <label className='my-auto'>State*</label>
                     <input className='my-auto' id='state' defaultValue={''} />
                  </div>
                  <div className='d-flex my-3 six-input'>
                     <label className='my-auto'>Zip Code*</label>
                     <input className='my-auto' id='zip_code' defaultValue={''} />
                  </div>
                  <div className='d-flex my-3 six-input'>
                     <label className='my-auto'>Email Address*</label>
                     <input className='my-auto' id='email_address' defaultValue={''} />
                  </div>
                  <div className='d-flex my-3 six-input'>
                     <label className='my-auto'>Phone Number*</label>
                     <input className='my-auto' id='phone_number' defaultValue={''} />
                  </div>
                  <button id='six_continue'></button>
               </div>
               <img
                  src='https://www.loandepotdigital.com/-/media/landingpages/images/lmbv3/lg-img.ashx?la=en&hash=E2FB71A3E943C4E7C7378BAE066D3C716ACC1F53'
                  alt='six-side'
                  style={{zoom: '150%', objectFit: 'contain', marginTop: 0, height: 'auto'}}
               />
            </div>
         );
      }
   };

   return (
      <div className='main-page'>
         <div className='w-100 d-flex py-3'>
            <img
               className='img-responsive logo'
               style={{marginLeft: '16rem', height: '5rem', objectFit: 'contain'}}
               alt='loanDepot'
               src={logoImg}
            ></img>
         </div>
         <div className='w-100 main-content'>
            <h1 className='my-2'>Rates near historic lows! Lock in before rates rise!</h1>
            <span className='arm_disclosure'>
               <a
                  href='/'
                  data-toggle='tooltip'
                  data-placement='bottom'
                  data-html='true'
                  title='5/1 ARM Rate - Rate displayed assumes that you are refinancing an owner-occupied single family home. Rate Loan refinance mortgage loan and assumes a loan amount of at least $200,000 and may include up to 2 points (1 point equals 1% of the loan) with all borrowers having a credit score of at least 740, a debt-to-income ratio of 43% or lower, a property loan-to-value of 60% or less with all asset and reserve requirements met. Home value for loan to value (LTV) uses automated valuation models (AVM). Actual appraised values may be different which could lead to a different interest rate availability. After five years, the interest rate becomes adjustable based on the following formula: Rate = Index + Margin. Caps are 5/2/5. Margin is fixed at 2.25%. Index is based on the one year Libor. Your APR may be higher if Mortgage Insurance is required or if an affiliated service provider is used. There is no guarantee that all borrowers will qualify and approval is subject to verification of credit, income, assets, and value, among other criteria. This is not a commitment to lend. Interest rates are valid as of October 31, 2023. Your actual rate and payment may be different based on many factors. Payments displayed do not include Mortgage Insurance, Taxes or Hazard Insurance payments, if required.<img id="tooltip_close" src="-/media/BB47E00209544EE4B08BAC5E3F2117EF.ashx" />'
                  role='tooltip'
                  tabIndex='0'
                  aria-live='polite'
               >
                  View Disclosures
               </a>
            </span>
         </div>
         <div className='d-flex flex-column w-100'>
            <label className='step-label'>{`STEP: ${step} OF 6`}</label>
            <div className='step-form mx-auto my-2'>
               {step < 6 && <img src={formbackgrounds[step - 1]} alt='step-form-back' className='w-100 h-100' />}
               <FormDiv />
            </div>
         </div>
         <div className='d-flex flex-column mx-auto' style={{width: 1100}}>
            <label className='mb-5'>
               By submitting this form, you are authorizing loanDepot.com, LLC and its corporate parent, affiliates and
               partners to deliver or cause to be delivered to you (including through agents and authorized
               third-parties) telemarketing promotions for products or services in addition to those about which you are
               applying, but that may be of interest to you using an automatic telephone dialing system or an artificial
               or prerecorded voice and text messages to the phone numbers you provided above. You are not required to
               sign this agreement as a condition of purchasing any property, goods, or services.
            </label>
            <label>
               This site is not authorized by the New York State Department of Financial Services. No mortgage loan
               applications for properties located in the state of New York will be accepted through this site.
            </label>
            <hr className='w-100' />
            <div className='d-flex footer-link mx-auto mb-4'>
               <a href='/'>NMLS Consumer Access Site</a>|<a href='/'>Privacy Policy</a>|<a href='/'>Accessibility</a>|
               <a href='/'>Terms of Use</a>|<a href='/'>Licensing</a>
            </div>
            <div className='d-flex mx-auto mb-4'>
               © 2009-2018 <a href='/'>loanDepot.com</a>, LLC. All rights reserved. NMLS#174457 | 6561 Irvine Center
               Drive, Irvine, CA 92618
            </div>
            <div className='d-flex mx-auto footer-logo mb-4'>
               <img
                  src='https://www.loandepotdigital.com/-/media/loandepot/images/global/navigation/bbb.ashx?la=en&hash=825159F9A34A6BF6FDAAA94C5B7EB31209149E53'
                  alt='footer-logo1'
               />
               <img
                  src='https://www.loandepotdigital.com/-/media/loandepot/images/global/navigation/fheo100.ashx?la=en&hash=D7DD88A93804D2965854D9ECA88179C31A7CB5E0'
                  alt='footer-logo2'
               />
            </div>
         </div>
      </div>
   );
}
