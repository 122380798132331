import {BrowserRouter, Routes, Route, Outlet, Navigate} from 'react-router-dom';

import './App.css';
import NoPage from './pages/NoPage';
import Main from './pages/Main';

function App() {
   return (
      <div className='App'>
         <BrowserRouter>
            <Routes>
               <Route path='/' element={<Outlet />}>
                  <Route index element={<Navigate to='/main' />} />
                  <Route path='main' element={<Main />} />
                  <Route path='*' element={<NoPage />} />
               </Route>
            </Routes>
         </BrowserRouter>
      </div>
   );
}

export default App;
